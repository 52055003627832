/* import __COLOCATED_TEMPLATE__ from './go-further-card.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { type Router } from '@ember/routing';
import { PRICING_5_X_EARLY_STAGE_SOLUTION_ID } from 'embercom/lib/billing';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';

export enum CardType {
  SaveWithEarlyStage = 'save-with-early-stage',
  SpeakToASpecialistSmall = 'speak-to-a-specialist-small',
  SpeakToASpecialistLarge = 'speak-to-a-specialist-large',
  HaveQuestions = 'have-questions',
  UpcomingFeatures = 'upcoming-features',
  WorkspaceSetupGuide = 'workspace-setup-guide',
  SuccessStories = 'success-stories',
}

export interface Args {
  cardType: CardType;
}

interface Signature {
  Args: Args;
  Blocks: any;
  Element: HTMLElement;
}

interface Config {
  background?: 'bg-neutral-container' | '';
  heading: string;
  body: string;
  button: {
    text: string;
    onClick?: () => void;
    dataIntercomBotTarget?: string;
  };
}

export default class GoFurtherCard extends Component<Signature> {
  @service declare intl: any;
  @service declare router: Router;
  @service declare purchaseAnalyticsService: $TSFixMe;
  @service declare appService: $TSFixMe;

  get cardType() {
    return this.args.cardType;
  }

  get background() {
    return this.cardData.background ?? '';
  }

  get heading() {
    return this.cardData.heading;
  }

  get body() {
    return this.cardData.body;
  }

  get buttonText() {
    return this.cardData.button.text;
  }

  get dataIntercomBotTarget() {
    return this.cardData.button.dataIntercomBotTarget;
  }

  @action
  onButtonClick() {
    return this.cardData.button.onClick?.();
  }

  get cardData() {
    return this.config[this.cardType];
  }

  private get config(): Record<CardType, Config> {
    return {
      [CardType.SaveWithEarlyStage]: {
        background: 'bg-neutral-container',
        heading: this.intl.t('expired-subscription.go-further-card.save-with-early-stage.heading'),
        body: this.intl.t('expired-subscription.go-further-card.save-with-early-stage.body'),
        button: {
          text: this.intl.t(
            'expired-subscription.go-further-card.save-with-early-stage.button-text',
          ),
          onClick: () => this.transitionToEarlyStageApplication(),
        },
      },
      [CardType.SpeakToASpecialistSmall]: {
        background: 'bg-neutral-container',
        heading: this.intl.t(
          'expired-subscription.go-further-card.speak-to-a-specialist-small.heading',
        ),
        body: this.intl.t('expired-subscription.go-further-card.speak-to-a-specialist-small.body'),
        button: {
          text: this.intl.t(
            'expired-subscription.go-further-card.speak-to-a-specialist-small.button-text',
          ),
          dataIntercomBotTarget: 'expired-subscription-speak-to-sales-assist',
        },
      },
      [CardType.SpeakToASpecialistLarge]: {
        background: 'bg-neutral-container',
        heading: this.intl.t(
          'expired-subscription.go-further-card.speak-to-a-specialist-large.heading',
        ),
        body: this.intl.t('expired-subscription.go-further-card.speak-to-a-specialist-large.body'),
        button: {
          text: this.intl.t(
            'expired-subscription.go-further-card.speak-to-a-specialist-large.button-text',
          ),
          dataIntercomBotTarget: 'expired-subscription-speak-to-sdr',
        },
      },
      [CardType.HaveQuestions]: {
        heading: this.intl.t('expired-subscription.go-further-card.have-questions.heading'),
        body: this.intl.t('expired-subscription.go-further-card.have-questions.body', {
          htmlSafe: true,
        }),
        button: {
          text: this.intl.t('expired-subscription.go-further-card.have-questions.button-text'),
          onClick: () => showNewMessageInIntercomWidget(),
        },
      },
      [CardType.UpcomingFeatures]: {
        heading: this.intl.t('expired-subscription.go-further-card.upcoming-features.heading'),
        body: this.intl.t('expired-subscription.go-further-card.upcoming-features.body'),
        button: {
          text: this.intl.t('expired-subscription.go-further-card.upcoming-features.button-text'),
          onClick: () => this.openUpcomingFeaturesPage(),
        },
      },
      [CardType.WorkspaceSetupGuide]: {
        heading: this.intl.t('expired-subscription.go-further-card.workspace-setup-guide.heading'),
        body: this.intl.t('expired-subscription.go-further-card.workspace-setup-guide.body'),
        button: {
          text: this.intl.t(
            'expired-subscription.go-further-card.workspace-setup-guide.button-text',
          ),
          onClick: () => this.openWorkspaceSetupGuide(),
        },
      },
      [CardType.SuccessStories]: {
        heading: this.intl.t('expired-subscription.go-further-card.success-stories.heading'),
        body: this.intl.t('expired-subscription.go-further-card.success-stories.body'),
        button: {
          text: this.intl.t('expired-subscription.go-further-card.success-stories.button-text'),
          onClick: () => this.openSuccessStoriesPage(),
        },
      },
    };
  }

  transitionToEarlyStageApplication() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: 'expired_subscription',
      place: 'save_with_early_stage_card',
      object: 'apply_for_early_stage_button',
    });
    this.router.transitionTo(
      'apps.app.teams-checkout.early-stage-application',
      this.appService.app.id,
      {
        queryParams: {
          solution_id: PRICING_5_X_EARLY_STAGE_SOLUTION_ID,
        },
      },
    );
  }

  openSuccessStoriesPage() {
    safeWindowOpen('https://www.intercom.com/customers#all-stories', '_blank');
  }

  openUpcomingFeaturesPage() {
    safeWindowOpen('https://www.intercom.com/changes/en', '_blank');
  }

  openWorkspaceSetupGuide() {
    safeWindowOpen(
      'https://www.intercom.com/help/en/collections/2094824-setting-up-your-workspace',
      '_blank',
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ExpiredSubscription::GoFurther::GoFurtherCard': typeof GoFurtherCard;
    'expired-subscription/go-further/go-further-card': typeof GoFurtherCard;
  }
}
