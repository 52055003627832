/* import __COLOCATED_TEMPLATE__ from './expired-subscription.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import {
  ExpirationReason,
  type HeaderCardButton,
  type Args as HeaderCardArgs,
} from './header/header-card';
import { CardType } from './go-further/go-further-card';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import type CardlessConversionModalService from 'embercom/services/cardless-conversion-modal-service';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';
import { tracked } from '@glimmer/tracking';

export interface Args {
  expirationReason: ExpirationReason | undefined;
  companySize: CompanySize;
  isBillingAdmin: boolean;
}

interface Signature {
  Args: Args;
  Blocks: any;
}

export enum CompanySize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

interface Config {
  heading: string;
  body: {
    billingAdmin: string;
    nonBillingAdmin: string;
  };
  buttons: {
    billingAdmin: HeaderCardButton[];
    nonBillingAdmin: HeaderCardButton[];
  };
  goFurtherCards: {
    billingAdmin: CardType[];
    nonBillingAdmin: CardType[];
  };
}

export default class ExpiredSubscription extends Component<Signature> {
  @service declare intl: any;
  @service declare cardlessConversionModalService: CardlessConversionModalService;
  @service declare router: RouterService;

  @tracked shouldShowAlertBillingAdminModal = false;

  get expirationReason() {
    return this.args.expirationReason ?? 'default';
  }

  get billingAdminAccess() {
    return this.args.isBillingAdmin ? 'billingAdmin' : 'nonBillingAdmin';
  }

  get headerCard(): HeaderCardArgs {
    return {
      heading: this.config[this.expirationReason].heading,
      body: this.config[this.expirationReason].body[this.billingAdminAccess],
      buttons: this.config[this.expirationReason].buttons[this.billingAdminAccess],
    };
  }

  get companySize(): CompanySize {
    return this.args.companySize ?? CompanySize.Small;
  }

  get goFurtherCardTypes() {
    return this.config[this.expirationReason].goFurtherCards[this.billingAdminAccess];
  }

  @action
  openCardlessConversionModal() {
    this.cardlessConversionModalService.toggle();
  }

  // TODO: Implement this
  @action
  openOneDollarTrialModal() {}

  @action
  transitionToBillingSettings() {
    this.router.transitionTo('apps.app.settings.workspace.billing.settings');
  }

  @action
  toggleAlertBillingAdminModal() {
    this.shouldShowAlertBillingAdminModal = !this.shouldShowAlertBillingAdminModal;
  }

  get config(): Record<ExpirationReason | 'default', Config> {
    return {
      [ExpirationReason.FreeTrialEnded]: {
        heading: this.intl.t('expired-subscription.header.free-trial-ended.heading'),
        body: {
          billingAdmin: this.intl.t(
            'expired-subscription.header.free-trial-ended.body.with-billing',
          ),
          nonBillingAdmin: this.intl.t(
            'expired-subscription.header.free-trial-ended.body.without-billing',
          ),
        },
        buttons: {
          billingAdmin: [
            {
              type: 'primary',
              label: this.intl.t('expired-subscription.header.free-trial-ended.button.primary'),
              action: this.openOneDollarTrialModal,
            },
            {
              type: 'secondary',
              label: this.intl.t('expired-subscription.header.free-trial-ended.button.secondary'),
              action: this.openCardlessConversionModal,
            },
          ],
          nonBillingAdmin: [this.alertBillingAdminButton],
        },
        goFurtherCards: {
          billingAdmin: this.cardsWithSpeakToASpecialistOrEarlyStageCard,
          nonBillingAdmin: this.cardsWithSpeakToASpecialistCard,
        },
      },
      [ExpirationReason.OneDollarTrialEnded]: {
        heading: this.intl.t('expired-subscription.header.one-dollar-trial-ended.heading'),
        body: {
          billingAdmin: this.intl.t(
            'expired-subscription.header.one-dollar-trial-ended.body.with-billing',
          ),
          nonBillingAdmin: this.intl.t(
            'expired-subscription.header.one-dollar-trial-ended.body.without-billing',
          ),
        },
        buttons: {
          billingAdmin: [
            {
              type: 'primary',
              label: this.intl.t(
                'expired-subscription.header.one-dollar-trial-ended.button.primary',
              ),
              action: this.openCardlessConversionModal,
            },
          ],
          nonBillingAdmin: [this.alertBillingAdminButton],
        },
        goFurtherCards: {
          billingAdmin: this.cardsWithSpeakToASpecialistOrEarlyStageCard,
          nonBillingAdmin: this.cardsWithSpeakToASpecialistCard,
        },
      },
      [ExpirationReason.SubscriptionCancelled]: {
        heading: this.intl.t('expired-subscription.header.subscription-cancelled.heading'),
        body: {
          billingAdmin: this.intl.t(
            'expired-subscription.header.subscription-cancelled.body.with-billing',
          ),
          nonBillingAdmin: this.intl.t(
            'expired-subscription.header.subscription-cancelled.body.without-billing',
          ),
        },
        buttons: {
          billingAdmin: [
            {
              type: 'primary',
              label: this.intl.t(
                'expired-subscription.header.subscription-cancelled.button.primary',
              ),
              action: this.openCardlessConversionModal,
            },
          ],
          nonBillingAdmin: [this.alertBillingAdminButton],
        },
        goFurtherCards: {
          billingAdmin: this.cardsWithSpeakToASpecialistCard,
          nonBillingAdmin: this.cardsWithSpeakToASpecialistCard,
        },
      },
      [ExpirationReason.NonPaymentSelfServe]: {
        heading: this.intl.t('expired-subscription.header.non-payment-self-serve.heading'),
        body: {
          billingAdmin: this.intl.t(
            'expired-subscription.header.non-payment-self-serve.body.with-billing',
          ),
          nonBillingAdmin: this.intl.t(
            'expired-subscription.header.non-payment-self-serve.body.without-billing',
          ),
        },
        buttons: {
          billingAdmin: [
            {
              type: 'primary',
              label: this.intl.t(
                'expired-subscription.header.non-payment-self-serve.button.primary',
              ),
              action: this.transitionToBillingSettings,
            },
          ],
          nonBillingAdmin: [this.alertBillingAdminButton],
        },
        goFurtherCards: {
          billingAdmin: [CardType.HaveQuestions, ...this.reusedCards],
          nonBillingAdmin: [CardType.HaveQuestions, ...this.reusedCards],
        },
      },
      [ExpirationReason.NonPaymentSalesLed]: {
        heading: this.intl.t('expired-subscription.header.non-payment-sales-led.heading'),
        body: {
          billingAdmin: this.intl.t(
            'expired-subscription.header.non-payment-sales-led.body.with-billing',
            { htmlSafe: true },
          ),
          nonBillingAdmin: this.intl.t(
            'expired-subscription.header.non-payment-sales-led.body.without-billing',
            { htmlSafe: true },
          ),
        },
        buttons: {
          billingAdmin: [
            {
              type: 'primary',
              label: this.intl.t(
                'expired-subscription.header.non-payment-sales-led.button.primary',
              ),
              action: () => showNewMessageInIntercomWidget(),
            },
          ],
          nonBillingAdmin: [
            {
              type: 'primary',
              label: this.intl.t('expired-subscription.chat-to-us'),
              action: () => showNewMessageInIntercomWidget(),
            },
          ],
        },
        goFurtherCards: {
          billingAdmin: this.reusedCards,
          nonBillingAdmin: this.reusedCards,
        },
      },
      [ExpirationReason.NewWorkspace]: {
        heading: this.intl.t('expired-subscription.header.new-workspace.heading'),
        body: {
          billingAdmin: this.intl.t('expired-subscription.header.new-workspace.body.with-billing'),
          nonBillingAdmin: this.intl.t(
            'expired-subscription.header.new-workspace.body.without-billing',
          ),
        },
        buttons: {
          billingAdmin: [
            {
              type: 'primary',
              label: this.intl.t('expired-subscription.header.new-workspace.button.primary'),
              action: this.openCardlessConversionModal,
            },
          ],
          nonBillingAdmin: [this.alertBillingAdminButton],
        },
        goFurtherCards: {
          billingAdmin: [this.speakToASpecialistCardType, CardType.WorkspaceSetupGuide],
          nonBillingAdmin: [this.speakToASpecialistCardType, CardType.WorkspaceSetupGuide],
        },
      },
      default: {
        heading: this.intl.t('expired-subscription.header.default.heading'),
        body: {
          billingAdmin: this.intl.t('expired-subscription.header.default.body.with-billing'),
          nonBillingAdmin: this.intl.t('expired-subscription.header.default.body.without-billing'),
        },
        buttons: {
          billingAdmin: [
            {
              type: 'primary',
              label: this.intl.t('expired-subscription.header.default.button.primary'),
              action: this.openCardlessConversionModal,
            },
          ],
          nonBillingAdmin: [this.alertBillingAdminButton],
        },
        goFurtherCards: {
          billingAdmin: this.cardsWithSpeakToASpecialistOrEarlyStageCard,
          nonBillingAdmin: this.cardsWithSpeakToASpecialistOrEarlyStageCard,
        },
      },
    };
  }

  get alertBillingAdminButton(): HeaderCardButton {
    return {
      type: 'primary',
      label: this.intl.t('expired-subscription.alert-billing-admin'),
      action: this.toggleAlertBillingAdminModal,
    };
  }

  get reusedCards() {
    return [CardType.UpcomingFeatures, CardType.SuccessStories];
  }

  get speakToASpecialistCardType(): CardType {
    return this.args.companySize === CompanySize.Large
      ? CardType.SpeakToASpecialistLarge
      : CardType.SpeakToASpecialistSmall;
  }

  get cardsWithSpeakToASpecialistCard() {
    return [this.speakToASpecialistCardType, ...this.reusedCards];
  }

  get cardsWithSpeakToASpecialistOrEarlyStageCard() {
    return [
      this.args.companySize === CompanySize.Small
        ? CardType.SaveWithEarlyStage
        : this.speakToASpecialistCardType,
      ...this.reusedCards,
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ExpiredSubscription::ExpiredSubscription': typeof ExpiredSubscription;
    'expired-subscription/expired-subscription': typeof ExpiredSubscription;
  }
}
